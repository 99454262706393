import(/* webpackMode: "eager" */ "/builds/dr-pam/possums-sleep-program/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/builds/dr-pam/possums-sleep-program/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/builds/dr-pam/possums-sleep-program/public/images/accent-photos/Breastfeeding.jpg");
;
import(/* webpackMode: "eager" */ "/builds/dr-pam/possums-sleep-program/public/images/accent-photos/dr-pam-sleeping-on-deck.jpg");
;
import(/* webpackMode: "eager" */ "/builds/dr-pam/possums-sleep-program/public/images/accent-photos/iStock-487664302.jpg");
;
import(/* webpackMode: "eager" */ "/builds/dr-pam/possums-sleep-program/public/images/accent-photos/lawrence-crayton-cf1cN3ofKUM-unsplash.jpg");
;
import(/* webpackMode: "eager" */ "/builds/dr-pam/possums-sleep-program/public/images/accent-photos/m-t-elgassier-G_acucnTJNw-unsplash.jpg");
;
import(/* webpackMode: "eager" */ "/builds/dr-pam/possums-sleep-program/public/images/logos/possums_logo-dark_bg.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/dr-pam/possums-sleep-program/src/components/layouts/AuthLinks.tsx");
